import * as React from "react"
import { StaticImage } from "gatsby-plugin-image"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import { graphql, useStaticQuery } from 'gatsby'
import { getImage } from 'gatsby-plugin-image';
import { BgImage } from 'gbimage-bridge';

import { Container } from "react-bootstrap"

const IndexPage2 = () => {
  const { placeholderImage123 } = useStaticQuery(
          graphql`
      query {
        placeholderImage123: file(relativePath: { eq: "incentive-3.jpg" }) {
          childImageSharp {
            gatsbyImageData(
              quality: 50, 
              webpOptions: {quality: 50}, 
              width: 2000,
              placeholder: BLURRED,
              blurredOptions: {width: 1000},
              formats: [AUTO, WEBP, AVIF]
            )
          }
        }
      }
    `
  )
  const pluginImage = getImage(placeholderImage123);
  // const crowdImage = getImage(crowd);

  return (
    <Layout>
      <SEO title="Incentive Planning | ECI Services" />
      <BgImage image={pluginImage} className="subhead">
        <div className="color-overlay d-flex pt-4 justify-content-center align-items-center">
          <div className="content-box">
            <Container>
              <h1>Incentive Planning</h1>
              
            </Container>
          </div>
        </div>
      </BgImage>
      <Container className="singleCol">
        <h2 className="orangeBarBelow mb-4">Recognize and reward your top performers with an experience they will not soon forget!</h2>
        <p>Top performers not only deliver the best, they want to experience the best. You need your travel incentive investment to provide great business returns. ECI can help you find the right incentive travel balance to fit both needs. We listen to your goals and we listen to your participants’ desires to find unique destinations and activities that will create an atmosphere of camaraderie and excitement which will leave a lasting impression and having everyone striving to be one of the elite invited to the next incentive program. Once we nail down the perfect venue, we handle all the details from flight plans to activity sign up to hotel check-ins so you can sit back, oversee your event and maybe even sneak in a little chill time.</p>
        
          <p>
        <StaticImage
              src="../../images/incentive_plan_pics_02.jpg"
              width={890}
              quality={72}
              formats={["AUTO", "WEBP", "AVIF"]}
              alt="A Corporate Meeting"
            />
        </p>
        </Container>
    </Layout>
          
  )
}

export default IndexPage2